<template>
  <b-container v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
    <b-row>
      <b-col cols="1"/>
      <b-col>
        <h2 style="margin-top: 4rem; margin-bottom: 1.3rem;">Displayansichten</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <img class="image" src="@/assets/pictures/step3.png" alt="tutorial" style="width: 100%; height: 100%;"/>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <p style="margin-bottom: 4rem; margin-top: 1.3rem;">Bei den linken Reglern, kannst du zwischen den Ansichten auf dem Display wechseln.<br>Zudem kannst du mit dem schwarzen Knopf zwischen den Modi des Fahrrads wählen: Normal und Automatisch.</p>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row class="fixed-bottom pageIndicatorPos">
      <b-col cols="2"/>
      <b-col cols="8">
        <PageIndicator route="bikeTutorial" :max="6" :pos="3"/>
      </b-col>
      <b-col cols="2"/>
    </b-row>
     <b-row>
      <NextButton text="Weiter" next @click="$router.push('/biketutorial/4')" />
    </b-row>
  </b-container>
</template>

<script>
import PageIndicator from '../components/PageIndicator';
import NextButton from '../components/NextButton';
  export default {
    name: 'BikeTutorial3',
    components: {
      PageIndicator,
      NextButton,
    },
    methods: {
      swipeLeft() {
        this.$router.push('/bikeTutorial/4')
      },
      swipeRight() {
       this.$router.push('/bikeTutorial/2')
      }
    }
  }
</script>

<style scoped>

.image {
  border-radius: 0.5rem;
}

</style>